
.p-read {
  min-width:815px;
}

.head-fly-fontbok {
  width: 43px;
  height: 19px;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  a {
    color: #369f9a;
  }

}

.atwhite {
  background-color: #fff;
}

.read-content {
  display: flex;
  justify-content: flex-start;
  * {
    user-select:none;
  }
  .read-contentl {
    border-right: 1px solid #d8d8d8;
    position: sticky;
    top: 0;

    .big-nav {
      width: 360px;
    }
    .readturn {
      height: 20px;
      font-family: 'Open Sans';
      color: #369f9a;
      font-size: 14px;
      font-weight: bold;
      margin: 47px auto 50px 50px;
    }
    .readCatalog {
      margin: 0px auto 40px 50px;
      height: 33px;
      font-family: 'Open Sans';
      font-size: 24px;
      font-weight: bold;
      color: #333333;
    }
    .readCatalogs-chapter {
      height: 22px;
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: bold;
      margin: 0px auto 33px 50px;
    }
    .readlistnum {

      margin-left: 50px;
      margin-bottom: 74px;

      height: 650px;

      .readolnum {
        height: 650px;

        overflow-y: scroll;
        ol {
          .colnumber {
            a {
              color: #369f9a;
            }
          }
          li {
            margin-bottom: 25px;
            height: 14px;

            margin-right: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #b0afae;
            font-family: 'Song Myung';
            font-size: 14px;
            span {
              margin-right: 10px;
            }
          }
          li:hover {
            color: #369f9a;
          }
        }
      }
      .readolnum::-webkit-scrollbar {
        width: 2px;

        // height:1px !important;
      }
      .readolnum::-webkit-scrollbar-button {
        display: block;
      }
      .readolnum::-webkit-scrollbar-track {

      }
      .readolnum::-webkit-scrollbar-thumb {
        background: #369f9a;
        border: 1px solid #369f9a

      }

    }
    .small-nav {
      display: none;
      width: 75px;
      .btn-exit {
        display: block;
        width: 76px;
        height: 76px;
        margin-bottom: 114px;
        border-bottom: 1px solid #d8d8d8;
        cursor:pointer;
        background: url('/build/images/read/arrow-left.png') center no-repeat;
      }
      .volume-sn {
        font-size: 14px;
        font-weight: bold;
        color: #333;
        text-align: center;
      }
      .chapter-list {
        margin-top: 10px;
        overflow-x: hidden;
        overflow-y: scroll;
        ul {
          padding-bottom:100px;
        }
        li {
          margin-top: 22px;
          font-size: 14px;
          color: #b0afae;
          text-align: center;
          font-weight: bold;
          cursor:pointer;
          a {
            display: none;
          }
        }
      }

      .small-logo {
        position: absolute;
        display: block;
        width: 74px;
        height: 76px;
        bottom: 0;
        left: 0;
        background: url('/build/images/read/logo-small.png') center no-repeat;
      }

      .chapter-list::-webkit-scrollbar {
        width: 2px;

        // height:1px !important;
      }
      .chapter-list::-webkit-scrollbar-button {
        display: block;
      }
      .chapter-list::-webkit-scrollbar-track {

      }
      .chapter-list::-webkit-scrollbar-thumb {
        background: #369f9a;
        border: 1px solid #369f9a

      }
    }
    .btn-fold {
      position: absolute;
      width: 50px;
      height: 37px;
      top: 115px;
      right: 0;
      background: url('/build/images/read/fold-out.png') 0 0 no-repeat;
      cursor:pointer;
      &.isBig {
        background: url('/build/images/read/fold-in.png') 0 0 no-repeat;
      }
    }
  }
  .read-contentl-botlog {
    background: #fff;
    position: absolute;
    bottom: 0px;
    width: 310px;
    height: 74px;
    padding-left: 50px;
    display: flex;
    align-items: center;

    img {
      height: 45px;
    }
    .logoback {
      display: none;
    }

  }
  .read-contentr {
    width: 100%;
    .read-contentrTit {
      width: 740px;
      height: 140px;
      font-family: 'Open Sans';
      font-size: 48px;
      font-weight: bold;
      color: #333333;
      margin: 110px auto 30px;
      text-align: center;
      line-height: 1.46;
    }
    .read-contentrAuthor {
      height: 22px;
      font-family: 'Open Sans';
      font-size: 16px;
      color: #899096;
      margin: 0 auto 50px;
      text-align: center;
      span:first-child {
        margin-right: 20px;
      }
    }
    .read-contentrwin {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 700px;
      margin: 0 auto;
      .read-contentrlinmid {
        background: url('/build/images/global/iconnew.png') no-repeat;
        background-position: 0px -750px;
        margin: 0 19px;
        width: 22px;
        height: 19px;
      }
      .read-contentrlin {
        width: 320px;
        height: 1px;
        background-color: #d8d8d8;
      }
    }

    .read-contentrchapter-hea {
      margin: 40px auto 50px;
      width: 112px;
      height: 33px;
      font-family: 'Open Sans';
      font-size: 24px;
      font-weight: bold;
    }
    .read-contentrchapter-na {
      width: 700px;
      height: 33px;
      font-family: 'Open Sans';
      font-size: 24px;
      font-weight: bold;
      color: #369f9a;
      margin: 0 auto;
      margin-top: 30px;
    }
    .read-contentrchapter-nafon {
      width: 700px;
      font-family: 'Open Sans';
      font-size: 32px;
      line-height: 1.4em;
      font-weight: bold;
      color: #333333;
      margin: 30px auto;
    }
    .read-contentr-transla {
      width: 700px;
      height: 22px;
      font-family: 'Open Sans';
      font-size: 16px;
      color: #899096;
      margin: 0px auto 50px;
      span {
        display: inline-block;
        margin-right: 30px;
      }
    }
    .read-contentr-HC {
      width: 700px;
      margin: 0 auto;
      p {
        width: 700px;
        font-family: 'Open Sans';
        font-size: 18px;
        color: #333333;
        line-height: 1.78;
        //text-align: justify;
        margin-bottom: 20px;
      }
      .loading {
        margin-top: 30px;
        width: 100%;
        text-align: center;
        .img-responsive {
          margin: 0 auto;
        }
      }
      .ellipsis {
        width: 28px;
        height: 25px;
        margin: 0 auto;
        font-family: 'Open Sans';
        font-size: 18px;
      }
      .drainage {
        width: 700px;
        height: 194px;
        background-color: #eeeeee;
        margin: 41px auto 59px;
        span {
          font-family: 'Song Myung';
          font-size: 16px;
          margin: 0 auto;
          text-align: center;
          height: 16px;
          padding-top: 50px;
          display: inline-block;
          width: 700px;
        }
        div {
          width: 180px;
          height: 50px;
          border: solid 2px #369f9a;
          margin: 44px auto 0px;
          text-align: center;
          font-family: 'Song Myung';
          font-size: 16px;
          color: #369f9a;
          line-height: 46px;
        }
        div:hover {
          background: #369f9a;
          color: #fff;
        }
      }

    }
    .read-contentr-circle {
      height: 35px;
      width: 35px;
      position: fixed;
      border-radius: 50%;
      bottom: 20px;
      right: 25px;
      background: #369f9a;
    }
    .read-contentr-set {
      background: url('/build/images/global/iconnew.png') no-repeat;
      background-position: -240px -740px;
      height: 35px;
      width: 35px;
      position: fixed;
      bottom: 20px;
      right: 25px;
    }
    .read-contentr-err {
      background: url('/build/images/global/iconnew.png') no-repeat;
      background-position: -190px -740px;
      height: 35px;
      width: 35px;
      position: fixed;
      bottom: 20px;
      right: 25px;
      cursor: pointer;
    }
    .read-contentr-set:hover {
      cursor: pointer;
    }
    .read-contentr-botbac {
      height: 74px;
      background: #fff; ////底部背景
      opacity: 0;
      position: fixed;
      bottom: 0px;
      align-items: center;
      width: 100%;
      border-top: 1px solid #d8d8d8;
    }
    .botbackcon {
      height: 74px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      width: 700px;
      .botbackcon-fon {
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 600;
        color: #899096;
      }
      .botcirw {
        margin-left: 20px;
        cursor: pointer;
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .botcirwbor {
        border: 1px solid #369f9a;
      }
      .botciry {
        margin: 0 20px;
        cursor: pointer;
        background: #f6efde;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .botcirb {
        margin-right: 20px;
        cursor: pointer;
        background: #3c4245;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .botbacksiz {
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 600;
        color: #899096;
      }
      .botbackad {
        font-size: 24px;
        width: 22px;
        margin-left: 20px;
        cursor: pointer;
      }
      .botbackbumb {
        font-family: 'Open Sans';
        font-size: 18px;
        color: #333333;
        margin: 0px 20px;
        width: 22px;
      }
      .botbackre {
        font-size: 24px;
        width: 22px;
        cursor: pointer;
      }
      .botbackbtn {
        width: 30px;
        height: 30px;
        border: 1px solid #eae8e8 !important;
        border-radius: 50%;
        line-height: 25px;
        text-align: center;
      }
    }
  }
  .read-contentrcli {
    padding: 0px;
    min-width: 700px;

  }

}

.atnight {
  background-color: #3c4245;
  .read-contentr .read-contentr-botbac {
    background: #3c4245;
  }
  .read-content .read-contentl .readCatalog {
    color: #a2a2a3;
  }
  .readCatalogs-chapter {
    color: #a2a2a3;
  }
  .read-contentrTit {
    color: #a2a2a3 !important;
  }
  .read-contentrchapter-hea {
    color: #a2a2a3;
  }
  .read-contentrchapter-nafon {
    color: #a2a2a3 !important;
  }
  .read-contentr-HC {
    p {
      color: #a2a2a3 !important;
    }
    p:first-child:first-letter {
      color: #a2a2a3;
    }
    .loading {
      color: #a2a2a3;
    }
  }
  .read-contentrlin {
    background-color: #47494b !important;
  }
  .read-contentrlinmid {
    background-position: -100px -750px !important;
  }
  .read-contentl {
    border-right: 1px solid #47494b;
  }
  .read-contentr-botbac {
    border-top: 1px solid #47494b !important;
  }
  .botbackbtn {
    color: #eae8e8 !important;
  }
  .botbackbumb {
    color: #eae8e8 !important;
  }
  .botcirb {
    border: 1px solid #369f9a;
  }
  .read-contentl-botlog {
    background: #3c4245;
    .logoback {
      display: block;
    }
    .logowhite {
      display: none;
    }
  }
  .ellipsis {
    color: #a2a2a3;
  }
  .drainage {
    background-color: #2e2f30 !important;
    span {
      color: #fff !important;
    }

  }
}

.atproeyes {
  background-color: #f6efde;
  .read-contentr-botbac {
    background: #f6efde !important;
  }
  .read-contentrlinmid {
    background-position: -50px -750px !important;
  }
  .read-contentrlin {
    background-color: #e2d7bc !important;
  }
  .read-contentl {
    border-right: 1px solid #e2d7bc;
  }
  .read-contentr-botbac {
    border-top: 1px solid #e2d7bc !important;
  }
  .botciry {
    border: 1px solid #369f9a;
  }
  .read-contentl-botlog {
    background: #f6efde;
  }
  .drainage {
    background-color: #eae3d2 !important;
  }
}
