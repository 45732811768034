// base css

//========================================================

body{
  min-width:1024px;
  font-family:$font-family-rgregular;
  line-height: 1;
}
*{
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
}
.icon-sprites{
  //position: relative;
  display:block;
  background-image: url("#{$img-path}global/icon.png?180821");
  background-repeat:no-repeat;

}
.fraction {
  display: inline-block;
  position: absolute;
  left: 100px;
  top: 1px;
  font-style: normal;
}
.star00 {
  background-position: -244px -150px;
}
.star10 {
   background-position: -226px -150px;
 }

.star20 {
  background-position: -206px -150px;
}
.star30 {
  background-position: -188px -150px;
}
.star40 {
  background-position: -168px -150px;
}
.star50 {
  background-position: -150px -150px;
}

.star05 {
  background-position: -226px -175px;
}

.star15 {
  background-position: -206px -175px;
}

.star25 {
  background-position: -188px -175px;
}

.star35 {
  background-position: -168px -175px;
}

.star45 {
  background-position: -150px -175px;
}

ul,li{
  margin:0;
  padding:0;
  list-style-type: none;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  outline:none;
  color: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1;
  font-family: $font-family-main;
  font-weight: normal;
}
img {
  vertical-align: baseline;
}
input:focus,
textarea:focus{
  outline:none;
}

.show-table{
  display:table;
  width:100%;
  height:100%;
}

.show-cell{
  display: table-cell;
  vertical-align:middle;
}

.success-message{
  color: #009a0d;
  font-size: 18px;
}

.error-message{
  margin-top: 10px;
  color: #f5685e;
  font-size: 16px;
  font-weight: bold;
}


.modal{

  .modal-content{
    border-radius:0;
  }

  .close{
    width: 13px;
    height: 13px;
    margin: 15px 18px 0 0;
    opacity: 1;
    background: url("../images/global/icon_global.png") -450px -150px no-repeat;
    &:focus {
      outline-style: none;
    }
  }
}

//select & option style.Base on bootstrap-select.

.bootstrap-select{
  border-radius:0;
}

.btn-select-style{
  height:36px;
  background-color: #fff;
  border: 1px solid #7d7d7d;
  border-radius:0;
  color: #999;

  .filter-option{
  }

  &.dropdown-toggle {
    &.btn{
      padding-top:5px;
      padding-left:20px;
      padding-right:25px;
    }
    .bs-caret{
      .caret {
        top:15px;
        width:11px;
        height:11px;
        background:url("#{$img-path}global/icon_global.png") -150px -198px no-repeat;
        border: none;
        transform:rotate(0deg);
        transition: transform .3s;
      }
    }
  }

  &+.dropdown-menu{
    top:34px;
    border: 1px solid #333;
    border-top:none;
    border-radius: 0;
    .dropdown-menu{
      >li{
        >a{
          height:2.3em;
          line-height:2em;
          &:focus,
          &:hover{
            color: #fff;
            text-decoration: none;
            background-color: $color-j-main;
          }
        }
      }
    }
  }

  &.show-list{
    border: 1px solid #333;
    border-bottom:none;
    border-radius:0;
    color:#444;
    .bs-caret{
      .caret {
        transform:rotate(-180deg);
      }
    }

  }
}

.breadcrumb {
  padding: 27px 0 26px 0;
  margin-bottom: 0;
  border-radius: 0;
  background-color: #fff;
  a {
    font-size: 12px;
    color: $color-gray2;
    &:hover {
      color: $color-gray1;
    }
  }
  li+li:before {
    padding: 0;
    color: #787878;
  }
}

#loadingModal{
  .modal-dialog{
    margin-top:300px;
    width:50px;
  }
  .modal-content{
    background-color: transparent;
    border:none;
    box-shadow:none;
  }
}

//rating:product
.prod-rank {
  margin-top: 5px;
  .icon-sprites {
    display: inline-block;
    width: 84px;
    height: 12px;

  }

  span {
    margin-left: 10px;
    font-size: 12px;
    color: #999;
  }
  i{
    vertical-align: middle;
  }
}


.siteCopyrightInfo {
  display: none;
}

