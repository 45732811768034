.p-article {
    font-family: $font-family-main;
    font-size: 14px;
    color: $color-main;
    .ser-ban {
      width: 100%;
      height: 230px;
      background-image: url("/build/images/global/p_banner.jpg");
      background-position: center top;
      background-size: 100% 100%;
      h3 {
        text-align: center;
        line-height: 230px;
        font-size: 48px;
        font-family: $font-family-tit;
        color: #fff;
      }
    }
  
    .cate {
      margin: 0 auto;
      padding-bottom: 130px;
      width: 1200px;
      overflow: hidden;
      padding-top: 20px;
      line-height: 25px;
      p {
        margin-top: 20px;
        line-height: 25px;
      }
      a {
        color: #2993d9;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }