.head-fly{
    height:659px;
    position:relative;
    overflow:hidden;
    min-width:1200px;
    .head-fly-font{
        width:100%;
        height:89px;
        background:url('/build/images/global/navigation-ink.png') no-repeat;
        background-size:cover;
        background-position:50% 50%;
        display:flex;
        justify-content: center; 
        position: absolute;
        top:0px;
    }
    .bamboo{
        width:100%;
        height:599px;
        position: absolute;
        top:60px;
        
        background:url('/build/images/global/head-background.png') no-repeat;
        background-size:cover;
        background-position:50% 50%;
        .narration{
            width:1190px;
            height: 64px; 
            font-family: 'Song Myung';
            font-size: 64px;
            margin:0 auto;
            margin-top:108px;
        }
        .narration-con{
            width:1190px;
            height: 144px;
            margin:0px auto ;
            margin-top:46px;
            font-family: 'Open Sans';
            font-size: 18px;
            line-height: 1.89;
            p{
                width:735px;
            }
        }
    }
}
.clearfix:after {
    content:""; 
    display: block; 
    clear:both; 
 }
.column-content{
    width:1190px;
    position: relative;
    margin:0 auto;
    margin-top:-137px;
    .column-content-head{
        width:1190px;
        height:100%;
        
        .column-ulnumw{
            ul{
                li{
                    margin-bottom:40px;
                    height:314px;  
                    padding:30px;
                    width:1190px;
                    border-radius: 6px;
                    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.06);
                    background-color: #ffffff;
                    .img-package{
                        width:180px;
                        height:254px;
                        position: relative;
                        .completed-s{
                            position: absolute;
                            width:91px;
                            height:91px;
                            background:url('/build/images/global/completed-s.png') no-repeat;
                            background-position:50% 50%;
                            right:-4px;
                            top:-4px
                            
                        }
                        .ongoing-s{
                            position: absolute;
                            width:91px;
                            height:91px;
                            background:url('/build/images/global/ongoing-s.png') no-repeat;
                            background-position:50% 50%;
                            right:-4px;
                            top:-4px;
                        }
                        .coming-s{
                            position: absolute;
                            width:91px;
                            height:91px;
                            background:url('/build/images/global/coming-s.png') no-repeat;
                            background-position:50% 50%;
                            right:-4px;
                            top:-4px;
                        }
                    }
                    img{
                        width:180px;
                        height:254px;
                    }
                    display: flex;
                    justify-content: center;
                    .column-ulnumw-rcon{
                        margin-left:30px;
                        width:1020px;
                        .column-lnamerstar{
                            height:26px;
                            display: flex;
                            justify-content: space-between;
                            line-height:26px;
                            font-family: 'Song Myung';
                            align-items:center;
                            margin-top:21px;
                            position: relative;
                            .ulnumw-rcon-nam{
                                font-size: 40px;
                                color: #333333;
                                line-height:48px;
                                width:700px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            
                            .star-int{
                                margin-right:50px;
                            }
                            .column-starfloat{
                                position: absolute;
                                top:0px;right:0px;
                                height: 30px;
                                font-family: 'Song Myung';
                                font-size: 30px;
                            }
                        }
                        .column-fantasy{
                            padding:6px 15px 6px;
                            height: 32px;
                            border-radius: 16px;
                            display: inline-block;
                            border: solid 1px #5eb2ae;
                            background-color: #d7eceb;
                            
                            text-align:center;
                            color:#369f9a;
                            font-family: 'Open Sans';
                            font-size: 16px;
                            margin-top:30px ;
                            margin-bottom:20px;
                        }
                        .ulnumw-rcon-desc{
                            width:920px;
                            height: 92px;
                            line-height: 1.67;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            font-family: 'Open Sans';
                            font-size: 18px;
                            overflow : hidden;
                            color: #333333;   
                        }
                        .column-latest-chapter{
                            line-height: 20px;
                            text-overflow: ellipsis;
                            margin-top:10px;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            font-family: 'Open Sans';
                            font-size: 14px;
                            overflow : hidden; 
                            .flag {
                                padding:0 6px;
                                height: 18px;
                                margin-right:6px;
                                border-radius: 9px;
                                display: inline-block;
                                background-color: #d7eceb;
                                text-align:center;
                                color:#369f9a;
                                font-family: 'Open Sans';
                                font-size: 12px;
                            }
                            a {
                                color:#999
                            }
                            .updateAt {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.headfixed{
    max-width: 1920px;
    height:60px;
    background:#303331;
    position: fixed;
    top:0px; 
    left:0px;     
    z-index:6666; 
    .head-logo{
        width:1190px;
        height:45px;
        margin:7px auto;
        .headlogo-back{
            height:45px;
            width:183px;
            background:url('/build/images/global/column-logo.png') no-repeat;
            background-size:cover;
            background-position:50% 50%;
            float:left;
        }
        .headlogo-book{
            height:45px;
            font-family: 'Open Sans';
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;
            float:right;
            line-height:45px;
            .booksimg{
                float:left;
                img{
                    width:17px;
                    height:22px;  
                    margin:10px 8px 0px;
                    
                }
            }   
        }
    }
}
.column-foot{
    width:100%;
    min-width:1200px;
    height:370px;
    background:url('/build/images/global/bottom-background.png') no-repeat;
    background-size:cover;
    margin-top:-126px;
    .column-foot-con{
        width:1190px;
        height:24px;
        padding-top:284px;
        margin:0px auto ;
        display: flex;
        justify-content:space-between;   
        align-items:center;
        .trademark{
            span{
                height: 19px;
                font-family: 'Open Sans';
                font-size: 14px;
                color: #333333;
            }
        }
        .column-foot-con-mid{
            display: flex;
            justify-content:center;
            align-items:center;
            font-family: 'Open Sans';
            font-size: 12px;
            font-weight: bold;
            span{
                height:8px;
                border-left:1px solid #000;
                margin:0 20px;
            }
        }
        .column-footmail{
            display: flex;
            align-items:center;
            height:19px;
            .foot-mailt{
                img{
                    width:17px;
                    height:16px;
                }
            }
            .foot-mailf{
                img{
                    width:8px;
                    height:18px;
                }
                margin:0 33px;
            }
            .foot-mailg{
                img{
                    width:24px;
                    height:15px;
                }
            }
        }
        
    }
}
// @media screen and (min-width:992px) and (max-width:1200px){
//     .column-content{
        
//     }
//     .column-ulnumw{
//         width:800px;
//         margin:50px auto 40px;
//         ul{
            
//             li{
//                width:700px; 
//                margin:0 auto;
//             }
//         }
//         .ulnumw-rcon-desc{
//             width:600px !important;
//             .ulnumw-rcon-desc{
//                 width:300px;
//             }
//         }

//     }
// }//笔记本中等屏幕
// @media screen and (min-width:768px) and (max-width:992px){
//     .column-content{
//         background:blue;
//     }
// }//平板小屏幕
// @media screen and (max-width:768px){
//     .column-content{
//         background:yellow;
//     }
// }