.p-flyinglines{
    max-width: 1920px;
    margin:0 auto;
}
.clearfix:after {
    display:block;clear:both;content:"";visibility:hidden;height:0;
 }
.head-flyindex{
    height:785px;
    margin:0 auto;
    position: relative;
    .bamboo{
        height:740px;
        top:45px;
    }
    .indexheadback{
        background:url('/build/images/global/index-head.jpg') no-repeat;
        background-position:50% 50%;
        min-width: 1200px;
        margin:0 auto;
        p{
            width:945px;
            margin:0 auto;
            font-family: 'Open Sans';
            font-size: 20px;
            line-height: 2;
        }
        .flylines{
            font-family: 'Open Sans';
            font-size: 40px;
            letter-spacing: -0.2px;
            color: #2ea3a3;
            margin-top:165px;
            font-weight: bold;
        }
        .mission{
            font-family: 'Song Myung';
            font-size: 64px;
        }
    }
}
.headfixed{
    min-width: 1200px;
    height:60px;
    background:#303331;
    position: fixed;
    top:0px; 
    left:0px;   
    right:0px;  
    margin:0 auto;
    .head-logo{
        width:1190px;
        height:45px;
        margin:7px auto;
        .headlogo-back{
            height:45px;
            width:183px;
            background:url('/build/images/global/column-logo.png') no-repeat;
            background-size:cover;
            background-position:50% 50%;
            float:left;
        }
        .headlogo-book{
            height:45px;
            font-family: 'Open Sans';
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;
            float:right;
            line-height:45px;
            padding-top:6px;
            .booksimg{
                float:left;
                img{
                    width:17px;
                    height:22px;  
                    margin:10px 8px 0px;
                    
                }
            }   
        }
    }
}
.homeframecon{
    width:100%;
}
.home-content{
    width:1184px;
    margin:80px auto 80px;
    .home-Translations{
        width:550px;
        height:48px;
        font-family: 'Song Myung';
        font-size: 48px;
        margin:0 auto;
    }
    .home-novelspread{
        width:900px;
        margin:40px auto;
        font-family: 'Open Sans';
        font-size: 18px;
        line-height: 2.22;
        text-align: center;
        color: #899096;
    }
    .home-hot{
        width:1182px;
        margin:0 auto;
        height:355px;
        ul{  
            li{
                width:304px;
                margin:0 45px;
                float:left;
                .hotimgcon{
                    margin:0 62px;
                    img{
                        width:180px;
                        height:255px;
                    }
                }
                .home-hotname{
                    font-family: 'Song Myung';
                    font-size: 30px;
                    color: #333333;
                    line-height: 1.2;
                    text-align:center;
                    width:304px;
                    margin-top:16px;
                }
            }
        }
    }
    .home-more{
        width: 176px;
        height: 60px;
        border-radius: 1px;
        border: solid 3px #369f9a;
        font-family: 'Open Sans';
        font-size: 24px;
        font-weight: bold;
        color: #369f9a;
        text-align:center;
        padding:14px 56px 13px 57px;
        margin:0 auto;
        margin-top:40px;
    }
}
.literature{
    height:588px;
    background:url('/build/images/global/vertandhori.png') no-repeat;
    background-position:50% 50%;
    min-width:1200px;
    
    .literature-con{
        padding-top:60px;
        .resources{
            width:444px;
            height:48px;
            font-family: 'Song Myung';
            font-size: 48px;
            margin:0 auto;
            color: #333333;
        }
        .vertandhori{
            width:190px;
            height:78px;
            margin:40px auto 30px;
            border-radius: 12px;
            box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
            background-color: #f6f6f6;
            padding:20px 32px;
            img{
                width:126px;
                height:38px
            }
        }
        .vertandhori-desc{
            font-family: 'Open Sans';
            font-size: 18px;
            width:900px;
            text-align:center;
            line-height: 2.22;
            margin:0 auto;
        }
    }
    
}
.translator{
    height:580px;
    width:1190px;
    margin:90px auto;
    .transl{
        .translimg{
            float:left;
            img{
                width:413px;
                height:285.6px;
            }
        }
        .translfont{
            float:left;
            margin-left:61px;
            margin-top:40px;
            p{
                font-family: 'Open Sans';
                font-size: 18px;
                line-height: 2.22;
                color:#333;
            }
            .resource{
                height: 48px;
                font-family: 'Song Myung';
                font-size: 48px;
                margin-bottom:37px;
            }
            
        }
    }
    .transr{
        .transrfont{
            float:left;
            margin-top:62px;
            p{
                font-family: 'Open Sans';
                font-size: 18px;
                line-height: 2.22;
                color:#333;
            }
        }
        .degree{
            float:left;
            width: 269px;
            height: 272px;
            background-color: #fafafa;
            margin-top:22.4px;
            margin-left:57px;
            position: relative;
            .degreetop{
                position: absolute;
                top:78px;
                left:87px;
            }
            .degreebot{
                position: absolute;
                bottom: 74px;
                left:50px;
            }
        }
        .transrimg{
            float:left;
            margin-top:22.4px;
            img{
                width:451px;
                height:272px;
            }
        }
    }
}
.home-footframe{
    height:950px;
    min-width:1200px;
    position: relative;
    background-color: #fafafa;
    
    .home-foot{
        width:100%;
        height:370px;
        position:absolute;
        background:url('/build/images/global/bottom-background.png') no-repeat;
        background-size:cover;
        bottom:0px;
        .foot-con{
            width:1190px;
            height:19px;
            display: flex;
            justify-content: space-between;
            margin:288px auto 63px;
            .trademark{
                span{
                    height: 19px;
                    font-family: 'Open Sans';
                    font-size: 14px;
                    color: #333333;
                }
            }
            .column-footmail{
                display:flex;
                align-items:center;
                .foot-mailt{
                    img{
                        width:17px;
                        height:16px;
                    }
                }
                .foot-mailf{
                    img{
                        width:8px;
                        height:18px;
                    }
                    margin:0 33px;
                }
                .foot-mailg{
                    img{
                        width:24px;
                        height:15px;
                    }
                }
            }
        }
    }
    .home-footheadimg{
        width:1190px;
        margin:0px auto;
        padding-top:82px;
        position: relative;
        ul{
            li{
                float:left;
                margin:0 15px;
                img{
                    width:200px;
                    height:232px;
                }
            }
        }
        .home-footheadmid{
            position: absolute;
            top:279px;
            left:115px;
            .teamfont{
                font-family: 'Song Myung';
                font-size: 48px;
                color: #333333;
                margin:86px 133px 0px;
            }
        }
        .home-footheadbot{
            position: absolute;
            top:476px;
        }
    }
}

