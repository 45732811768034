
.p-err {

  .container {
    position: relative;
    width: 978px;
    margin:70px auto;
    .title {
      position: absolute;
      width: 460px;
      top: 485px;
      left: 267px;
      color: #fff;
      h3 {
        font-family:'Song Myung';
        font-size: 36px;
        line-height: 48px;
        text-align: center;
      }
      .text {
        margin-top: 25px;
        font-size: 18px;
        line-height: 36px;
      }
    }
    .btn-home {
      position: absolute;
      top: 730px;
      left: 360px;
      display: block;
      width: 241px;
      height: 60px;
      border: 2px solid  #369f9a;
      font-family:'Song Myung';
      font-size: 18px;
      line-height: 56px;
      color: #369f9a;
      text-align: center;

    }
  }

}