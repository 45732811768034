.clearfix:after {
    display:block;clear:both;content:"";visibility:hidden;height:0;
 }
.details-headbac{
    width:1190px;
    margin:0 auto;
    background:#fff;
    position:relative;
    border-top:1px solid #fff;
    margin-top:-493px;
    margin-bottom:125px;
    .details-heacon{
        width:1070px;
        height:473px;
        margin:34px 60px 86px;
        // background:#fff;
        position: relative;
        .pathname{
            margin-bottom:32px;
            color: #899096;
            span{
                margin:0 11px;
                
                height: 22px;
                font-family: 'Open Sans';
                font-size: 16px;
            }
            .pathnovelname{
                color:#333
            }
            span:first-child{
                margin-left:0px;
            }
        }
        .details-lr{
            
        }
        .details-bookfl{
            float:left;
            width:300px;
            height:422px;
            position:relative;
            .completed-b{
                width:148px;height:148px;
                position:absolute;
                background:url('/build/images/global/completed-b.png') no-repeat;
                right:-8px;
                top:-8px;
            }
            .ongoing-b{
                width:148px;height:148px;
                position:absolute;
                background:url('/build/images/global/ongoing-b.png') no-repeat;
                right:-8px;
                top:-8px;
            }
            .coming-b{
                width:148px;height:148px;
                position:absolute;
                background:url('/build/images/global/coming-b.png') no-repeat;
                right:-8px;
                top:-8px;
            }
            img{
                width:300px;
                height:422px;
            }
        }
        .details-bookfr{
            float:left;
            margin-left:50px;
            .details-novelname{
                width:720px;
                display:flex;
                justify-content:space-between;
                position:relative;
                .novelnamehid{
                    line-height:48px;
                    font-family: 'Song Myung';
                    font-size: 40px;
                    color: #333333;       
                    width:550px;          
                }
                .star-int{
                    margin-right:50px;
                    margin-top:20px;
                }
                .details-starscore{
                    position:absolute;
                    right:0px;
                    top:13px;
                    font-size:30px;
                    font-family: 'Song Myung';
                }
            }
            .details-autor{
                height: 22px;
                font-family: 'Open Sans';
                font-size: 16px;
                color: #899096;
                span{
                    height: 22px;
                    font-family: 'Open Sans';
                    font-size: 16px;
                    color: #333333;
                    margin-left:10px;
                }
            }
            .details-type{
                padding:6px 15px;
                height: 32px;
                border-radius: 16px;
                display: inline-block;
                border: solid 1px #5eb2ae;
                background-color: #d7eceb;
                text-align:center;
                color: #369f9a;
                margin-top:40px;
                margin-bottom:30px;
                cursor:pointer;
                font-family: 'Open Sans';
                font-size: 16px;
            }
            .details-lastupd{
                
                height: 19px;
                font-family: 'Open Sans';
                font-size: 16px;
                color: #899096;
                margin:20px auto;
                span{
                    height: 19px;
                    font-family: 'Open Sans';
                    font-size: 16px;
                    color:#333;
                    margin-left:10px;
                }
            }
            .details-lastupdraw{
                
                height: 19px;
                font-family: 'Open Sans';
                font-size: 16px;
                color: #899096;
                span{
                    height: 20px;
                    font-family: 'Open Sans';
                    font-size: 16px;
                    color:#333;
                    margin-left:10px;
                }
            }
            .details-read{
                width: 250px;
                height: 54px;
                background-color: #369f9a;
                font-family: 'Song Myung';
                font-size: 20px;
                color:#fff;
                margin-top:30px;
                line-height:54px;
                text-align:center;
            }
            .locking{
                
                background:url('/build/images/global/locking.png') no-repeat;
                background-color:#a8adb8;
                background-position:40% 50%;
                padding-left:22px;
                
                
            }
            .details-share{
                height:26px;
                margin-top:35px;
                width:143px;
                ul{
                    li{
                        float:left;
                        img{
                            width:24px;
                            height:24px;
                        }
                        
                        cursor:pointer;
                    }
                
                    .sharet{
                        img{
                            width:26px ;
                            height:24px;
                        }
                    }
                    .sharef{
                        margin:0 15px;
                    }
                    .sharep{
                        margin-right:15px;
                    }
                }
            }
        }
    
    }
    .details-con{
        width:1070px;
        margin-top:86px;
        margin:0 auto;
        .details-conrig{
            .details-conrig-tab{
                display: flex;
                align-items:center;
                height:47px;
                .details-conrig-about{
                    width:22px;
                    height:23px;
                    background:url('/build/images/global/iconnew.png') no-repeat;
                    background-position:-600px -150px;
                    cursor:pointer;
                    span{
                        margin-left:32px;
                        line-height:23px;
                        height: 24px;
                        font-family: 'Open Sans';
                        font-size: 20px;
                        font-weight: 600;
                        color: #333333;
                    }
                    .details-conrig-aboutbot{
                        width:82px;
                        border-bottom:2px solid #369f9a;
                        margin-top:-1px;
                        margin-bottom:20px;
                    }
                    .details-navline{
                        width:1070px;
                        height:1px;
                        margin-top:23px;
                        background-color: #d8d8d8;
                    }
                }
                
                .details-conrig-table{
                    margin-left:110px;
                    height:23px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    span{
                        margin-left:6px;
                        line-height:22px;
                        height: 24px;
                        font-family: 'Open Sans';
                        font-size: 20px;
                        font-weight: 600;
                        color: #899096;
                        margin-top:4px;
                    }
                    
                    .details-conrig-tablebot{
                        width:188px;
                        border-bottom:2px solid #369f9a;
                        margin-top:70px;
                        margin-left:-188px;
                        display:none;
                    }
                    .details-config-tablesh{
                        width:23px;
                        height:22px;
                        margin-left:-10px;
                        background:url('/build/images/global/iconnew.png') no-repeat;
                        background-position:-750px -150px;
                        cursor:pointer;
                    }
                }
            }
            .details-conrig-aboutcon{
                width:936px;
                margin-top:46px;
                margin-bottom:50px;
                .details-conrig-aboutcon-tit{
                    color: #333333;
                    height: 36px;
                    font-family: 'Open Sans';
                    font-size: 26px;
                    font-weight: 600;
    
                }
                .details-conrig-aboutcon-con{
                    margin-top:15px;
                    width:1070px;
                    p{
                        font-size:18px;
                        font-family: 'Open Sans';
                        line-height: 1.56;
                        color:#333;
                        margin-top:20px;
                    }
                }
            }
            .details-conrig-tablecon{
                height:100%;
                display:none;
                margin-top:43px;
                margin-bottom:50px;
                p{
                    height: 24px;
                    color: #369f9a;
                    font-family: 'Open Sans';
                    font-size: 18px;
                    font-weight: bold;
                }
                ul{
                    margin:0 auto;
                    li{
                        float:left;
                        font-family: 'Song Myung';
                        font-size: 16px;
                        width:500px;
                        height:60px;
                        line-height:60px;
                        border-bottom:1px solid #eae8e8;
                        margin-right:35px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: #333;
                        span{
                            margin-right:10px;
                            color: #333;
                            font-family: 'Song Myung';
                            font-size: 16px;
                        }
                    }
                    li:hover{
                        color:#369f9a;
                        span{
                            color:#369f9a;
                        }
                    }
                }
            }
    
        }
    
    }
        
}

.details-conrig-tabadd{
    .details-conrig-tablebot{
        display:block !important;
        
    }
    .details-config-tablesh{
        background-position:-700px -150px !important;
    }
    .details-conrig-table{
        span{
            color:#333 !important;
        }
    }
    .details-conrig-about{
        background-position:-650px -150px !important;
    }
    .details-conrig-about{
        span{
            color: #899096 !important;
        }
    }
    .details-conrig-aboutbot{
        display:none !important;
    }
    .details-conrig-aboutcon{
        display:none !important;
    }
    .details-conrig-tablecon{
        display:block !important;
    }
}
.star-int{
    width:110px;
    height:18px;
    
    .headstar10{
        background:url("/build/images/global/star.png") no-repeat;
         background-position: -93px 0px;
         height:18px;
    }
    .headstar20{
        background:url("/build/images/global/star.png") no-repeat;
        background-position: -70px -0px;
        height:18px;
    }
    .headstar30{
        background:url("/build/images/global/star.png") no-repeat;
        background-position: -47px -0px;
        height:18px;
    }
    .headstar40{
        background:url("/build/images/global/star.png") no-repeat;
        background-position: -24px -0px;
        height:18px;
    }
    .headstar50{
        background:url("/build/images/global/star.png") no-repeat;
         background-position: 0px 0px; 
         height:18px;
    }
    .headstar00{
        background:url("/build/images/global/star.png") no-repeat;
        background-position: -116px -0px;
        height:18px;
    }
    .headstar05{
        background:url("/build/images/global/star.png") no-repeat;
        background-position: -93px -150px;
        height:18px;
    }
    .headstar15{
        background:url("/build/images/global/star.png") no-repeat;
        background-position: -93px -175px;
        height:18px;
    }
    .headstar25{
        background:url("/build/images/global/star.png") no-repeat;
        background-position: -70px -0px;
        height:18px;
    }
    .headstar35{
        background:url("/build/images/global/star.png") no-repeat;
        background-position: -47px -0px;
        height:18px;
    }
    .headstar45{
        background:url("/build/images/global/star.png") no-repeat;
        background-position: -24px 0px;
        height:18px;
        
    }
}


