// font face
// ----------------------------------


@font-face {
  font-family: 'Song Myung';
  src: url('#{$font-path}Song_Myung/SongMyung-Regular.ttf') ;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 200;
  src: url('#{$font-path}Open_Sans/opensans-regular-webfont.ttf'),
  url('#{$font-path}Open_Sans/opensans-regular-webfont.woff2') format('woff2'),
  url('#{$font-path}Open_Sans/opensans-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'OpenSans-Italic';
  font-weight: 200;
  //src: url('#{$font-path}Open_Sans/OpenSans-Italic.ttf');
  src: url('#{$font-path}Open_Sans/opensans-italic-webfont.woff2') format('woff2'),
  url('#{$font-path}Open_Sans/opensans-italic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'OpenSans-Bold';
  font-weight: 200;
  //src: url('#{$font-path}Open_Sans/OpenSans-Bold.ttf');
  src: url('#{$font-path}Open_Sans/opensans-bold-webfont.woff2') format('woff2'),
  url('#{$font-path}Open_Sans/opensans-bold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Merriweather-Regular';
  font-weight: normal;
  font-style: normal;
  //src: url('#{$font-path}Merriweather/Merriweather-Regular.ttf'),
  src: url('#{$font-path}Merriweather/merriweather-regular-webfont.woff2') format('woff2'),
  url('#{$font-path}Merriweather/merriweather-regular-webfont.woff') format('woff');
}

/*

@font-face {
  font-family:'Glyphicons Halflings';
  src:url('#{$font-path}glyphicons/glyphicons-halflings-regular.eot');   !* IE9 Compat Modes *!
  src:url('#{$font-path}glyphicons/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),!* IE6-IE8 *!
  url('#{$font-path}glyphicons/glyphicons-halflings-regular.woff') format('woff'),  !* Modern Browsers *!
  url('#{$font-path}glyphicons/glyphicons-halflings-regular.ttf') format('truetype'),!* Safari, Android, iOS *!
  url('#{$font-path}glyphicons/glyphicons-halflings-regular.svg#glyphicons-halflingsregular') format('svg'); !* Legacy iOS *!

}*/
